.auto-container {
    padding: 0 4rem 4rem 4rem;
}

.head-section {
    padding: 4rem;
}

.head-container {
    padding-top: 50px;
    display: flex;
}

.head-left {
    width: 55%;
    font-size: 4rem;
    line-height: 80px;
    font-weight: 600;
    text-align: left;
}

.span-underline-use-cases {
    filter: saturate(120%);
    background-image: url('/public/picture/underline.svg');
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 0.5rem;
    display: inline-block;
}

.head-right {
    width: 40%;
}

.head-right>img {
    width: 100%;
}

/*********************************** tech-section  ********************************************/
.tech-section {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding: 100px 0 80px 0;
}

.tech-left {
    width: 40%;
    text-align: left;
    display: flex;
}

.lefttech-part {
    align-items: center;
    align-content: center;
    display: flex;
}

.tech {
    font-size: 72px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0px;
}

.lefttech-detail {
    color: #615d5d;
    font-size: 18px;
}

.tech-right {
    width: 60%;
}

.tech-container {
    display: flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}

.tech-column {
    overflow-wrap: break-word;
}

.grid-item {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px 20px 20px 20px;
    width: calc((100%/3) - 1rem);
    margin: 0.5rem;
    float: left;
    overflow: hidden;
    height: 180px;
    border-color: #e7e7e7;
}

.grid-figure {
    margin: 0;
    background-color: #f3f7fe;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 30px;
    width: 100%;
    height: 100%;
}

.grid-figure img {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    object-fit: contain;
}

.grid-figure img:hover {
    scale: 1.1;
    transition: all 0.3s;
}

@media screen and (max-width:1024px) {
    .auto-container {
        padding: 0 2rem;
    }

    .grid-item {
        width: calc((100%/2) - 1rem);
    }
}

@media screen and (max-width:500px) {
    .lefttech-container {
        text-align: center;
    }

    .grid-item {
        width: 100%;
        height: 140px;
    }

    .grid-figure {
        padding: 15px;
    }

    .grid-figure>img {
        height: 100%;
    }
}

@media screen and (max-width:768px) {
    .auto-container {
        padding: 0 1rem;
    }

    .tech-section {
        display: block;
        padding-top: 50px;
    }

    .tech-left,
    .tech-right {
        width: 100%;
    }

    .tech {
        font-size: 45px;
    }
}

/********************************** how-section  ******************************/
.how-title {
    font-size: 60px;
}

.how-content {
    padding: 0% 10%;
}

.how-container {
    margin-top: 50px;
    text-align: left;
    display: flex;
    gap: 40px;
    width: 100%;
    height: 100%;
}

.how-work {
    background: linear-gradient(rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 20px;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0.5px 0px 0px inset, rgba(155, 165, 255, 0.15) 0px -2px 40px 0px, rgba(223, 223, 255, 0.3) 0px -2px 10px 0px;
    padding: 80px 32px;
    position: relative;
    width: 50%;
}

.how-work-title {
    font-size: 30px;
}

@media screen and (max-width:768px) {
    .how-container {
        display: block;
    }

    .how-work {
        width: 100%;
        margin-top: 20px;
        padding: 20px;
    }
}

/******************************** offer-section  ****************************/
.offer-section {
    margin-top: 100px;
}

.offer-title {
    font-size: 60px;
}

.offer-part {
    width: 100%;
    margin-top: 20px;
}

.offer-inner {
    display: flex;
    gap: 30px;
    width: 100%;
    height: 100%;
}

.offer-child {
    width: 33%;
    border-radius: 20px;
    padding-top: 2%;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0.5px 0px 0px inset, rgba(155, 165, 255, 0.15) 0px -2px 40px 0px, rgba(223, 223, 255, 0.3) 0px -2px 10px 0px;
}

.offer-icon {
    fill: #a933c6;
    color: #FF8A8A;
    width: 3em;
    height: 3em;
    margin-bottom: 1em;
}

.offer-detail {
    font-size: 27px;
    font-weight: 700;
    line-height: 2rem;
    padding: 0 10% 10% 10%;
}

@media screen and (max-width:768px) {

    .offer-inner,
    .head-container {
        display: block;
    }

    .offer-child,
    .head-left,
    .head-right {
        width: 100%;
        margin-top: 20px;
    }

    .head-left {
        font-size: 30px;
    }

    .offer-detail {
        padding: 0 10% 5% 10%;
    }

    .heading-title {
        font-size: 50px;
    }
}

.faq-mains {
    width: 100%;
}

.faq-box {
    height: auto;
    padding: 10px;
}

.box-faq-title {
    line-height: 30px;
}

@media screen and (max-width:768px) {
    .head-section {
        padding: 1rem 2rem;
    }

    .auto-container {
        padding: 0 2rem;
    }

    .head-container {
        padding-top: 0;
    }

    .head-left {
        line-height: 50px;
        text-align: center;
    }

    .heading-content {
        padding: 0;
    }
}