@keyframes ldio-9doe6q2j3bp {
    0% {
        transform: translate(12px, 80px) scale(0);
    }

    25% {
        transform: translate(12px, 80px) scale(0);
    }

    50% {
        transform: translate(12px, 80px) scale(1);
    }

    75% {
        transform: translate(80px, 80px) scale(1);
    }

    100% {
        transform: translate(148px, 80px) scale(1);
    }
}

@keyframes ldio-9doe6q2j3bp-r {
    0% {
        transform: translate(148px, 80px) scale(1);
    }

    100% {
        transform: translate(148px, 80px) scale(0);
    }
}

@keyframes ldio-9doe6q2j3bp-c {
    0% {
        background: #b335bd
    }

    25% {
        background: #b335bd
    }

    50% {
        background: #b335bd
    }

    75% {
        background: #b335bd
    }

    100% {
        background: #b335bd
    }
}

.ldio-9doe6q2j3bp div {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translate(80px, 80px) scale(1);
    background: #b335bd;
    animation: ldio-9doe6q2j3bp 4.545454545454546s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-9doe6q2j3bp div:nth-child(1) {
    background: #b335bd;
    transform: translate(148px, 80px) scale(1);
    animation: ldio-9doe6q2j3bp-r 1.1363636363636365s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-9doe6q2j3bp-c 4.545454545454546s infinite step-start;
}

.ldio-9doe6q2j3bp div:nth-child(2) {
    animation-delay: -1.1363636363636365s;
    background: #b335bd;
}

.ldio-9doe6q2j3bp div:nth-child(3) {
    animation-delay: -2.272727272727273s;
    background: #b335bd;
}

.ldio-9doe6q2j3bp div:nth-child(4) {
    animation-delay: -3.409090909090909s;
    background: #b335bd;
}

.ldio-9doe6q2j3bp div:nth-child(5) {
    animation-delay: -4.545454545454546s;
    background: #b335bd;
}

.loadingio-spinner-ellipsis-z0qckxtut8o {
    width: 200px;
    height: 100vh;
    display: inline-block;
    overflow: hidden;
}

.ldio-9doe6q2j3bp {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;

    /* see note above */
}

.ldio-9doe6q2j3bp div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */