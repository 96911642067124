.feature-section1 {
    width: 100%;
    grid-column-gap: 64px;
    grid-row-gap: 64px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 50px;
    display: flex;
}

.text-container {
    width: 100%;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
}

.textin-container {
    width: 100%;
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.heading-and-supporting-text-3 {
    width: 100%;
    max-width: 768px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.heading-and-subheading-2 {
    width: 100%;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.display-md-semibold {
    /* color: #101828; */
    text-align: center;
    font-size: 45px;
    font-weight: 600;
    line-height: 44px;
}

.text-xl-regular-2 {
    /* color: #475467; */
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.feature-section2 {
    width: 100%;
    grid-column-gap: 64px;
    grid-row-gap: 64px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
}

.grid-container {
    width: 100%;
    height: 100%;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    display: flex;
    padding-bottom: 100px;
}

.feature-text {
    width: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    margin-top: 30px;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0.5px 0px 0px inset, rgba(155, 165, 255, 0.15) 0px -2px 40px 0px, rgba(223, 223, 255, 0.3) 0px -2px 10px 0px;
}

.featured-icon {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    flex: 0 auto;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    display: flex;
    box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
}

.vectors-wrapper-9 {
    width: 24px;
    height: 24px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    display: flex;
}

.supporting-text-4 {
    width: 100%;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.text-xl-semibold {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
}

.text-md-regular-6 {
    color: #7f90a9;
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
    font-weight: 400;
    line-height: 24px;
}

@media screen and (max-width:768px) {
    .grid-container {
        display: block;
    }

    .display-md-semibold {
        font-size: 30px;
        line-height: 30px;
    }

    .feature-section1 {
        padding-top: 100px;
    }
}