.blog-content {
    padding-top: 80px;
}

.title-container {
    padding: 0 10%;
}

/************ blog-container ************/
.blog-container {
    margin-top: 120px;
    width: 100%;
    padding-bottom: 50px;

}

.blog-heading-title {
    font-size: 50px;
}

.blog-section {
    padding: 1rem;
    width: 100%;
    justify-content: stretch;
    border-radius: 30px;
    margin-top: 30px;
    /* background-color: rgb(24, 26, 34); */
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0.5px 0px 0px inset, rgba(155, 165, 255, 0.15) 0px -2px 40px 0px, rgba(223, 223, 255, 0.3) 0px -2px 10px 0px;
}

.blog-img {
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    height: 25rem;
    object-fit: cover;
    object-position: center;
}

.blog-img:hover {
    scale: 1.02;
    transition: all 0.5s ease-in-out;
}

.blog-desc {
    padding: 2rem 0;
    text-align: justify;
    line-height: 2rem;
}

.blog-desc h3 {
    font-weight: 600;
    line-height: 50px;
    text-align: left;
}

.blog-desc p {
    margin-top: 30px;
    letter-spacing: 1px;
}

.blog-desc a {
    font-size: 20px;
    font-weight: 600;
    color: black;
}

@media screen and (max-width:1000px) {
    .blog-content {
        padding-top: 4rem;
    }

    .blog-heading-title {
        font-size: 30px;
    }

    .title-container {
        padding: 0 10%;
        text-align: left;
    }

    .heading-title {
        font-size: 30px;
    }

    .heading-content {
        font-size: 15px;
        margin-top: 20px;
    }

    .blog-desc h3 {
        line-height: 30px;
    }

    .blog-desc p {
        margin-top: 20px;
        letter-spacing: 0;
        line-height: 1.5rem;
    }

    .blog-img {
        height: 15rem;
    }

    .blog-container {
        margin-top: 30px;
    }
}

/************************************** blogmore ********************************************/

.blogmore-content {
    display: flex;
}

.blogmore-container {
    width: calc(100%-550px);
    width: -moz-calc(100% - 550px);
    width: -webkit-calc(100% - 550px);
    flex: 1 1 auto;
    overflow-x: hidden;
}

.blogmore-form {
    margin-top: 50px;
}

.blogmore-input {
    margin-bottom: 30px;
}

.blogmore-desc {
    padding: 10% 5% 5% 15%;
}

.blogmore-desc h2 {
    margin-bottom: 50px;
    line-height: 50px;
    letter-spacing: 2px;
    font-weight: 600;
}

.blogmore-desc p {
    text-align: justify;
    margin-bottom: 50px;
    letter-spacing: 1.8px;
    line-height: 35px;
    font-size: 18px;
}

.to-back {
    top: 50px;
    left: 30px;
    position: fixed;
    border-radius: 50%;
    padding: 15px;
    background-color: white;
    z-index: 1;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border: none;
    flex: 0 0 550px;
}

.blogmore-img {
    width: 100%;
    height: 500px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    object-fit: cover;
    object-position: center;
}

.blogmore-section2 {
    top: 0;
    right: 0;
    width: 500px;
    height: 100vh;
    background: linear-gradient(160deg, rgba(92, 21, 142, 1) 0%, rgb(96, 19, 154) 38%, rgba(86, 9, 121, 1) 100%);
    color: white;
    /* position: fixed; */
    padding: 0 50px;
    position: sticky;
    z-index: 1;
    overflow: auto;
}

.blogmore-fixed {
    position: fixed;
}

.blogmore-logo {
    padding: 30px 0 10px;
}

.subscribe-btn {
    border-radius: 30px;
    padding: 15px 40px;
    border: 1px solid white;
    margin-left: 200px;
    transition: all 0.2s;
    background-color: #5f0a87;
    color: white;
}

.subscribe-btn:hover {
    background-color: rgb(72, 9, 104);
}

.social-icon {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid white;
    padding-left: 250px;
}

.social-icon img {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    -webkit-filter: invert(100%);
    /* Safari/Chrome */
    filter: invert(100%);
}

.social-icon img:hover {
    scale: 1.2;
    cursor: pointer;
}

.navbar-a {
    display: none;
}

@media screen and (max-width:1100px) {
    .blogmore-section2 {
        display: none;
    }

    .blogmore-container {
        width: 100%;
    }

    .blogmore-carousel {
        width: 100%;
        /* height: 25rem; */
    }

    .blogmore-desc {
        padding: 7%;
    }

    .blogmore-desc h2 {
        line-height: 30px;
        margin-bottom: 30px;
        text-align: left;
    }

    .blogmore-desc p {
        letter-spacing: 1px;
        line-height: 20px;
        font-size: 15px;
        text-align: left;
    }

    .blogmore-img {
        height: 350px;
    }

    .navbar-a {
        display: block;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .to-back {
        top: 100px;
    }

}

/******************************************** blog-footer **************************************/
.blogfooter-content {
    color: white;
    position: relative;
    background: linear-gradient(60deg, rgba(92, 21, 142, 1) 0%, rgb(96, 19, 154) 38%, rgba(86, 9, 121, 1) 100%);
    padding: 3% 3% 0.1% 3%;
}

.blogfooter-container {
    display: flex;
}

.blogfooter-logo,
.blogfooter-join {
    width: 30%;
    padding: 0 20px;
    text-align: left;
}

@media screen and (max-width:1300px) {
    .blogfooter-container {
        display: block;
    }

    .blogfooter-logo,
    .blogfooter-join {
        width: 310px;
    }
}