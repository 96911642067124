.page-main {
    justify-content: center;
    align-items: center;
}

.main-container {
    padding-top: 50px;
    margin-left: 17.5%;
    width: 65%;
}

.text-span {
    font-size: 100px;
    font-weight: 800;
    color: purple;
}

.text-xl-regular {
    margin-top: 3rem;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
}

.main-btn {
    margin-top: 5rem;
    padding-bottom: 70px;
    font-weight: 600;
}

.call-btn {
    margin-top: 30px;
}

.button-3 {
    cursor: pointer;
    background-color: #e8eeff;
    color: purple;
    font-size: 18px;
    border-radius: 30px;
    padding: 15px 20px;
    text-decoration: none;
}

.button-3:hover {
    background-color: #ced7f2;
    transition-duration: 1s;
    transition-property: background-color;
}

/* anmation */
.maintext-animation {
    /* color: #e5e5e5; */
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.animation {
    height: 75px;
    overflow: hidden;
    margin-left: 1rem;
    font-weight: 600;
    border: none;
    font-size: 60px;
}

.animation>div {
    height: 100%;
    padding: 0 30px;
    margin: 0;
}

.animation div:first-child {
    animation: text-animation 10s infinite;
}

@keyframes text-animation {
    25% {
        margin-top: -80px;
    }

    50% {
        margin-top: -150px;
    }

    75% {
        margin-top: -80px;
    }

    100% {
        margin-top: 0px;
    }
}

@media screen and (max-width:768px) {
    .page-main {
        /* padding-top: 50px; */
        background-size: cover;
    }

    .text-span {
        font-size: 70px;
    }

    .text-xl-regular {
        margin-top: 3rem;
        font-size: 20px;
        text-align: center;
    }

    .main-container {
        width: 90%;
        margin-left: 5%;
    }

    .maintext-animation {
        display: block;
    }

    .animation {
        font-size: 50px;
    }

    .animation>div>span {
        padding: 0;
    }
}