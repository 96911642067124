.faq-content {
  margin-top: 150px;
  padding-bottom: 50px;
}

.faq-container {
  padding: 0 10%;
  display: flex;
}

.faq-title {
  width: 20%;
  font-size: 60px;
}

.faq-main {
  width: 80%;
}

@media screen and (max-width:786px) {
  .faq-content {
    margin-top: 50px;
  }

  .faq-container {
    display: block;
  }

  .faq-title,
  .faq-main {
    width: 100%;
  }
}

.accordion {
  margin: auto;
}

.accordion input {
  display: none;
}

.box {
  position: relative;
  background-image: url('/public/picture/background.jpg');
  background-size: cover;
  margin-top: 10px;
  height: auto;
  transition: all .15s ease-in-out;
  overflow: hidden;
  border-radius: 15px;
  padding: 10px 0;
  color: white;
}

/* 
.box::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
} */

.box-title {
  font-size: 25px;
  text-align: left;
  line-height: 30px;
  letter-spacing: 2px;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box-content {
  width: calc(100% - 40px);
  padding: 30px 20px;
  font-size: 13pt;
  text-align: left;
  display: none;
}

.box-close {
  position: absolute;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
}

input:checked+.box {
  height: auto;
  margin: 16px 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
}

input:checked+.box .box-title {
  border-bottom: 1px solid rgba(0, 0, 0, .18);
}

input:checked+.box .box-content,
input:checked+.box .box-close {
  display: inline-block;
}

.arrows section .box-title {
  padding: 0 15% 0 5%;
  width: 100%;
}

.arrows section .box-title:before {
  position: absolute;
  display: block;
  content: '\276f';
  font-size: 20pt;
  right: 20px;
  transition: transform .15s ease-in-out;
  color: white;
}

input:checked+section.box .box-title:before {
  transform: rotate(90deg);
}

input:checked+section.box {
  border: 3px solid purple;
}

@media screen and (max-width:768px) {
  .faq-container {
    padding: 0;
  }

  .box-title {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0cm;
  }

  .box-content {
    padding: 15px;
  }

  .arrows section .box-title:before {
    font-size: 10pt;
  }
}