.footer-content {
    color: white;
    position: relative;
    background-image: url('/public/picture/background.jpg');
    background-size: cover;
}

.footer-container {
    padding: 50px;
    display: flex;
}

.footer-left {
    width: 35%;
}

.footer-left-title {
    font-size: 30px;
}

.appbtn-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 5%;
}

.appbtn {
    cursor: pointer;
    border: 1px solid white;
    border-radius: 30px;
    padding: 10px;
    width: 45%;
    transition: all .2s;
}

.appbtn a {
    text-decoration: none;
    color: #fff;
}

.appbtn:hover {
    background-color: rgb(72, 9, 104);
}

.appbtn svg {
    width: 20px;
    height: 20px;
    margin: 0 5px 5px 0;
}

.footer-right {
    margin-left: 10%;
    width: 45%;
}

.footer-input-area {
    margin-bottom: 60px;
    position: relative;
}

.text-area {
    display: flex;
}

.footer-input {
    width: 100%;
    /* background: 0; */
    border: 0;
    outline: none;
    border-radius: 5px;
}

.footer-subscribe-btn {
    border-radius: 30px;
    padding: 10px 40px;
    border: 1px solid white;
    color: white;
    background-color: inherit;
    transition: all .2s;
}

.footer-subscribe-btn:hover {
    background-color: rgb(72, 9, 104);
}

@media screen and (max-width:950px) {
    .footer-container {
        display: block;
    }

    .footer-right {
        margin-left: 0;
    }

    .footer-col {
        width: 100%;
        padding-bottom: 50px;
    }

    .appbtn-section {
        display: block;
    }

    .appbtn {
        margin-top: 20px;
        width: 100%;
    }

    .footer-subscribe-btn {
        width: 100%;
    }
}

@media screen and (max-width:768px) {
    .text-area {
        display: block;
    }

    .footer-input-area {
        margin-bottom: 20px;
    }
}