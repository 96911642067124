.slide-content {
    /* width: 100%; */
    display: flex;
    margin-top: 100px;
    height: auto;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0.5px 0px 0px inset, rgba(155, 165, 255, 0.15) 0px -2px 40px 0px, rgba(223, 223, 255, 0.3) 0px -2px 10px 0px;
    color: white;
}

.slide-menu {
    background-color: #00031f;
    width: 35%;
}

.slide-in {
    padding: 10rem 2rem;
}

.slide-menu-btn {
    display: inline-block;
    text-decoration: none;
    padding: .9rem 1.3rem;
    font-size: .8rem;
    border-radius: 2rem;
    line-height: 1;
    margin: 2rem .2rem;
    transition: .3s;
    text-transform: uppercase;
    background-color: transparent;
    color: #FAFAFA;
    border: 1px solid #FAFAFA;
}

.slide-menu-btn:hover {
    color: #00031f;
    border: 1px solid #fff;
    background-color: #FAFAFA;
}

/********** slide-container ***********/
.slide-container {
    width: 65%;
    background-color: #58337b;
    /*disable text-select*/
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* padding-right: 70px; */
    align-items: stretch;
}

.slides {
    height: 100%;
    width: 100%;
    padding: 5%;
}

.slide-part {
    height: 100%;
    border-radius: 20px;
    background-color: white;
    color: black;
    cursor: grab;
    text-align: left;
}

.slide-part h5 {
    padding: 10px 30px;
}

.slide-part p {
    padding: 0 30px;
}

.slide-btn {
    margin-left: 30px;
    margin-bottom: 30px;
}

.slide-image {
    border-radius: 20px;
    width: 90%;
    margin: 10px 5%;
    height: 200px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    object-fit: cover;
    object-position: center;
}

.slide-image-title {
    padding-top: 20px;
}

.slide-btn {
    text-decoration: none;
    display: inline-block;
    padding: .9rem 1.3rem;
    font-size: .8rem;
    border-radius: 2rem;
    line-height: 1;
    transition: .3s;
    border: 1px solid #58337b;
    color: #58337b;
    background-color: transparent;
}

.slide-btn:hover {
    color: #FAFAFA;
    background-color: #58337b;
}

@media screen and (max-width:768px) {
    .slides {
        padding: 2rem 5rem
    }


    .slide-content {
        margin-top: 0;
        display: block;
    }

    .slide-in {
        padding: 2rem 0;
    }

    .slide-menu,
    .slide-container {
        width: 100%;
    }
}

@media screen and (max-width:530px) {
    .slides {
        padding: 2rem 1rem;
    }

    .slide-part h5,
    .slide-part p {
        padding: 0 10px;
    }

    .slide-btn {
        margin-bottom: 10px;
    }
}