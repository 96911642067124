*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.App {
  text-align: center;
  background-color: #f3f7fe;
  color: #000000;
}

body::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}

body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #00aeff, #a68eff);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, .12) 0 3px 13px 1px;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
}

.trans {
  position: relative;
  transform: translateX(-150px);
  opacity: 0;
  transition: 3s all ease;
}

.reveal.active,
.trans.active {
  transform: translateX(0);
  opacity: 1;
}

.heading-title {
  /* color: #FFFFFF; */
  font-size: 53px;
  font-weight: 700;
  line-height: 1.1em;
  letter-spacing: 1px;
}

.heading-shine {
  background: linear-gradient(120deg, #C98AFF 0%, #C98AFF 26%, #f2a2b4 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.heading-content {
  margin-top: 50px;
  padding: 0 3rem;
  font-size: 22px;
  color: #615d5d;
  /* font-family: "Nunito", Sans-serif; */
  font-weight: 700;
}