.working-content {
    width: 100%;
    padding-top: 100px;
}

.working-container {
    display: flex;
}

.working-section1 {
    width: 45%;
    padding: 30px;
    align-self: center;
}

.howworking-title {
    font-size: 50px;
    font-weight: 700;
    padding: 30px 0;
}

.how-image {
    width: 100%;
}

.how-image img {
    width: 100%;
    border-radius: 20%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.working-section2 {
    width: 55%;
    padding: 30px;
}

.working-desc {
    text-align: left;
    font-size: 18px;
}

.working-text-container {
    text-align: left;
}

@media screen and (max-width:768px) {
    .working-content {
        padding-top: 50px;
    }

    .howworking-title {
        padding: 0;
        font-size: 35px;
    }

    .working-container {
        display: block;
    }

    .working-section1,
    .working-section2 {
        width: 100%;
    }
}